<template>
    <div id="plans"> 
        <div class="featured-property-section planes-background">
            <div class=""> 
                <div class="row m-0 mt-2">
                    <div class="col-sm-12 col-xs-12 col-md-4 plan-a">
                        <div class="plan-tarjeta card mb-0">
                            <p class="plan-precio mb-0">$200 <span>CL</span></p>
                            <p class="plan-descripcion mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                            </p>
                            <p class="planes-nombre">ORO</p>
                            <div class="planes-linea"></div> 
                            <p class="plan-texto">Lorem ipsum dolor sit amet.</p>
                            <div class="mt-40"> 
                                
                            </div>
                            <div class="mt-2">
                                <b-form-group label="Meses" label-for="meses">
                                    <b-input-group>
                                        <b-form-input @keypress="onlyNumber" v-model="form.how_many_months" type="text" placeholder="Ejemplo : 1" name="meses"/>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group label="Número de proyectos" label-for="numero_proyectos">
                                    <b-input-group>
                                        <b-form-input @keypress="onlyNumber" v-model="$v.form.how_many_projects.$model" type="text" placeholder="Ejemplo : 1" name="numero_proyectos"/>
                                    </b-input-group>
                                </b-form-group>
                                
                                <button class="btn btn-primary w-100" type="button" @click="mandarAPantallaResumen('oro')" :disabled="$v.form.$invalid">Contratar</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xs-12 col-md-8 plan-b">
                        <div class="plan-tarjeta card mb-0">
                            <h3 class="title-banner-project ">Agregar adicionales</h3>
                            <div class="mb-3">
                                <v-select 
                                    multiple
                                    v-model="form.projects"
                                    label="name"
                                    placeholder="Selecciona los proyectos"
                                    :options="filteredProjects"  
                                    :disabled="form.how_many_projects <= 0" >
                                    <span slot="no-options">No se encontraron opciones</span>
                                </v-select>
                            </div>
                            <div class="row m-0 mb-2" v-for="(project,indexP) in form.projects" :key="indexP">
                                <div class="col-4 p-0">{{project.name}}</div>
                                <div class="col-8 p-0">
                                    <v-select 
                                        multiple
                                        v-model="project.aditionals"
                                        label="name"
                                        placeholder="Selecciona los adicionales"
                                        :options="aditionals">
                                        <span slot="no-options">No se encontraron opciones</span>
                                    </v-select>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div> 
            </div>   
        </div>
  </div>
</template>

<script>
    import {mapActions,mapState} from 'vuex' 
    import {required, numeric, email, minLength, maxLength, url, decimal , requiredIf} from 'vuelidate/lib/validators' 

    export default{
    name: 'PlansCreate',
    components: {},
    data(){
        return {
            form:{
                price:200,
                how_many_months:null,
                how_many_projects:null,
                projects:[],
                total:0,
                subtotal:0,
            },
            initialProjects:[],
            filteredProjects:[],
            aditionals:[
                'Pago Reserva',
                'Pago Promesa',
                'Chatbot',
                'Rukan',
            ],
        }
    }, 
    validations:{
        form:{
            price:{required,numeric},
            how_many_months:{required,numeric},
            how_many_projects:{required, numeric},
            projects:{required},
        }
    },
    mounted(){
        this.form = {
            price:200,
            how_many_months:null,
            how_many_projects:null,
            projects:[]
        };
        this.fetchProjectsRelatedToMe()
        .then((res) => { 
            this.initialProjects = res.data.projectsrelatedtome;
            this.filteredProjects = this.initialProjects; 
        });
    },
    watch: {
        form:{
            deep: true,
            handler(){    
                this.saveFiltersPlans(this.form); 
            }
        },
        "form.how_many_projects"(val) {
            this.form.projects.map((e) => {
                if(this.form.how_many_projects < this.form.projects.length){
                    this.form.projects.pop();
                }
            })
        },
        "form.projects"(val) {
            if(this.form.how_many_projects < this.form.projects.length){
                this.form.projects.pop();
            }
        }
    },
    methods: { 
        ...mapActions('profile', ['fetchProjectsRelatedToMe']),
        ...mapActions('plans', ['saveFiltersPlans']),
        mandarAPantallaResumen(){ 
            this.$router.push({ name: 'plans-contract'});
        },
        onlyNumber ($event) { 
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which); 
            if (keyCode == 46) {
                $event.preventDefault();
            }
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
    },
    computed: {
        ...mapState('projects',['projects']),
        ...mapState('plans',['plans']),

    },
    }
</script>

<style scoped> 
.marker-pink{
    color: #de1f69;
    margin-right: 5px;
}
.mt-40{
    margin-top: 40px;
}
.btn-pink{
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
    border-color: none;
}
.plan-descripcion{
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 11px !important;
    color: #636363;
}
.plan-texto{
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px !important;
    color: #636363;
}
.plan-precio span{
    font-size: 25px;
}
.plan-precio{
    font-weight: bolder;
    font-size: 40px;
    color: #17223d;
    line-height: 1.2em;
}
.plan-tarjeta{
    height: 100%;
    border-radius: 10px; 
    padding: 30px; 
}
.planes-nombre{
    font-size: 30px;
    color: #de1f69 !important;
    font-weight: 700;
}
.container-planes{
    margin-bottom: 100px;
}
.container-planes p {
    margin-bottom: 0px;
}
.container-planes h3{
    color: #17223d;
    font-weight: 700;
}
.container-planes h2{
    color: #17223d;
    font-weight: 700;
}
.container-planes h4 {
    color: #de1f69;
    font-weight: 300;
    font-size: 15px;
}
.plan-a{
    padding-left: 0px;
}
.plan-b{
    padding-right: 0px;
}
.planes-linea{
    margin-top: 0px;
    border: 1px solid #636363;
    width: 20%;
    margin-bottom: 10px;
}
@media (max-width: 992px) {
    .plan-a{
        padding-right: 0px;
    }
    .plan-b{
        padding-left: 0px;
    }
}
</style>
